/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.rich-text-editor {
    min-height: 75px !important;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.form-control {
    height: 40px !important;
}
